import { useEffect } from 'react';

import {
  REFERRAL_LOCAL_STORAGE_KEY,
  REFERRAL_URL_PARAM
} from '@app/constants/referal';

export function useSaveReferralToLocalStorage() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const referral = urlParams.get(REFERRAL_URL_PARAM);

    if (referral) {
      localStorage.setItem(REFERRAL_LOCAL_STORAGE_KEY, referral);
    }
  }, []);
}
