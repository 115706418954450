import { useCallback } from 'react';

import { REFERRAL_LOCAL_STORAGE_KEY } from '@app/constants/referal';
import { fetchWrapper } from '@app/utils/fetchWrapper';

export function useRequestOtp() {
  const sendOtp = useCallback(async (email: string) => {
    const referralCode = localStorage.getItem(REFERRAL_LOCAL_STORAGE_KEY);

    return await fetchWrapper(
      '/api/auth/otp/send',
      {
        method: 'POST',
        body: JSON.stringify({ email, referralCode })
      },
      {
        error: 'Could not request OTP token. Please, try again later.'
      }
    );
  }, []);

  return sendOtp;
}
