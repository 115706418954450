import React, { useCallback, useState } from 'react';

import { EmailForm } from './components/EmailForm';
import { OtpForm } from './components/OtpForm';

export const LoginForm = () => {
  const [email, setEmail] = useState('');

  const onSubmitEmail = useCallback((email: string) => {
    setEmail(email);
  }, []);

  return (
    <div>
      {!email && <EmailForm onSubmitEmail={onSubmitEmail} />}

      {email && <OtpForm email={email} />}
    </div>
  );
};
