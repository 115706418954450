import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import React, { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import VerificationInput from 'react-verification-input';
import * as yup from 'yup';

import { useAuthContext } from '@app/context/AuthContext';
import { LoginBtn } from '@app/features/login/components/LoginForm/components/LoginBtn';
import { useRequestOtp } from '@app/features/login/components/LoginForm/hooks/useRequestOtp';
import { ErrorMessage } from '@app/ui-kit/ErrorMessage';
import { InputLabel } from '@app/ui-kit/InputLabel';

import { useGetTimePassed } from './hooks/useGetTimePassed';
import { useVerifyOtpToken } from './hooks/verifyOtpToken';
import s from './OtpForm.module.scss';
import { getOtpLabel } from './utils/getOtpLabel';

type Form = {
  otp: string;
};

const schema = yup.object<Form>().shape({
  otp: yup
    .string()
    .required('Required')
    .length(6, 'Verification code must be exactly 6 digits')
});

interface OtpFormProps {
  email: string;
}

export const OtpForm = (props: OtpFormProps) => {
  const { email } = props;

  const verifyOtp = useVerifyOtpToken();
  const { loginWithOTP } = useAuthContext() || {};

  const requestOtp = useRequestOtp();

  const { reset, passed } = useGetTimePassed();

  const canResend = passed === 60;

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<Form>({
    defaultValues: {
      otp: ''
    },
    resolver: yupResolver<Form>(schema),
    mode: 'onSubmit'
  });

  const onSubmit = useCallback(
    async (form: Form) => {
      const { otp } = form;

      const { data, success } = await verifyOtp(email, otp);

      if (success) {
        const { token } = data || {};

        if (token && loginWithOTP) {
          loginWithOTP(token);
        }
      }
    },
    [email]
  );

  const onResend = useCallback(() => {
    reset();
    requestOtp(email);
  }, [email, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <InputLabel>{getOtpLabel(email)}</InputLabel>
      <Controller
        name="otp"
        control={control}
        render={({ field }) => (
          <VerificationInput
            {...field}
            placeholder="0"
            length={6}
            classNames={{
              container: s.optContainer,
              character: cn(s.inputRoot, s.otpInput)
            }}
            inputProps={{
              type: 'tel'
            }}
          />
        )}
      />
      <ErrorMessage>{errors?.otp?.message}</ErrorMessage>

      <LoginBtn
        type="button"
        view="secondary"
        onClick={onResend}
        disabled={!canResend}
      >
        {canResend ? (
          <span>Resend code</span>
        ) : (
          <span>
            Resend code in <b>{60 - passed}</b> sec
          </span>
        )}
      </LoginBtn>
      <LoginBtn>Confirm</LoginBtn>
    </form>
  );
};
