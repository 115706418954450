import { useCallback, useEffect, useState } from 'react';

export function useGetTimePassed() {
  const [passed, setPassed] = useState(0);
  const [id, setId] = useState<NodeJS.Timeout>();

  useEffect(() => {
    function runTimer(passed: number) {
      const newPassed = passed + 1;

      setPassed(newPassed);

      if (newPassed < 60) {
        const id = setTimeout(() => {
          runTimer(newPassed);
          clearTimeout(id);
        }, 1000);

        setId(id);
      }
    }

    if (passed === 0) {
      runTimer(passed);
    }
  }, [passed]);

  const reset = useCallback(() => {
    clearTimeout(id);
    setPassed(0);
  }, [id]);

  return {
    reset,
    passed
  };
}
