import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { LoginBtn } from '@app/features/login/components/LoginForm/components/LoginBtn';
import { useRequestOtp } from '@app/features/login/components/LoginForm/hooks/useRequestOtp';
import { Input } from '@app/ui-kit';
import { InputLabel } from '@app/ui-kit/InputLabel';

type Form = {
  email: string;
};

const schema = yup.object<Form>().shape({
  email: yup.string().email('Invalid email format').required('Required')
});

interface EmailFormProps {
  onSubmitEmail: (email: string) => void;
}

export const EmailForm = (props: EmailFormProps) => {
  const { onSubmitEmail } = props;

  const requestOtp = useRequestOtp();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Form>({
    defaultValues: {
      email: ''
    },
    resolver: yupResolver<Form>(schema),
    mode: 'onSubmit'
  });

  const onSubmit = useCallback(async (data: Form) => {
    const { email } = data;

    const { success } = await requestOtp(email);

    if (success) {
      onSubmitEmail(email);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <InputLabel>Email</InputLabel>
      <Input
        {...register('email')}
        type="email"
        placeholder="Email address"
        error={errors.email?.message}
      />

      <LoginBtn>Next</LoginBtn>
    </form>
  );
};
