import React, { PropsWithChildren } from 'react';

import { Button, ButtonProps } from '@app/ui-kit';

import s from './LoginBtn.module.scss';

interface LoginBtnProps extends PropsWithChildren {
  disabled?: boolean;
  onClick?: () => void;
  view?: ButtonProps['view'];
  type?: ButtonProps['type'];
}

export const LoginBtn = (props: LoginBtnProps) => {
  const { disabled, children, onClick, ...rest } = props;

  return (
    <Button
      type="submit"
      size="large"
      onClick={onClick}
      className={s.root}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Button>
  );
};
