import { useCallback } from 'react';

import { REFERRAL_LOCAL_STORAGE_KEY } from '@app/constants/referal';
import { fetchWrapper } from '@app/utils/fetchWrapper';

export function useVerifyOtpToken() {
  const sendOtp = useCallback(async (email: string, token: string) => {
    const referralCode = localStorage.getItem(REFERRAL_LOCAL_STORAGE_KEY);

    return await fetchWrapper<{ token: string }>('/api/auth/otp/verify', {
      method: 'POST',
      body: JSON.stringify({ email, token, referralCode })
    });
  }, []);

  return sendOtp;
}
