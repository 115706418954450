import cn from 'classnames';
import { PropsWithChildren } from 'react';

import s from './InputLabel.module.scss';

interface InputLabelProps extends PropsWithChildren {
  className?: string;
}

export const InputLabel = (props: InputLabelProps) => {
  const { children, className } = props;

  return <div className={cn(s.root, className)}>{children}</div>;
};
